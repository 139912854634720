<template>
  <transition name="modal">
    <div class="modal-mask" @click="hide()" style="overflow-y: scroll; background-color: rgba(0, 0, 0, .8);">
      <div class="modal-container" @click.stop>
        <div style="position: absolute;top: 30px; right: 40px;z-index: 9998; ">
          <img @click="hide()" style="cursor:pointer;width: 24px; height: 24px;" :src="$root.CDN_SITE + 'icon/ic_close_white.svg'">
        </div>
        <div class="modal-inner" v-if="getModalInfo">
          <p class="filter-title">Filtrele</p>
          <div style="display: flex;flex-direction: column">
            <label class="filter-label">Ara</label>
            <input v-model="searchTerm" type="text" class="filter-text">
          </div>
          <label class="filter-label">Alt Kategori</label>
          <select v-model="selectedCategoryInfo" class="super-drop-tek-cizgi">
            <option v-for="category in categoryInfo" :value="category.slug">{{ category.name }}</option>
          </select>

          <label class="filter-label">Süre</label>
          <select v-model="duration" class="super-drop-tek-cizgi">
            <option :value="null">Hepsi</option>
            <option :value="1">24 saat</option>
            <option :value="3">3 gün</option>
            <option :value="7">1 hafta</option>
          </select>

          <label class="filter-label">Sıralama</label>

          <select v-model="orderBy" class="super-drop-tek-cizgi order">
            <option :value="1">Tavsiye Edilen</option>
            <option :value="2">Yüksek Puan</option>
            <option :value="3">Yeniler</option>
          </select>

          <button class="done-button" @click="doneButton()">TAMAM</button>
        </div>
      </div>
    </div>
  </transition>

</template>

<script>

  export default {
    name: "src-pages-modals-gigCategoryFilter-v1",
    data() {
      return {
        duration: null,
        selectedCategoryInfo: null,
        categoryInfo: null,
        searchTerm: null,
        orderBy: 1
      }
    },
    methods: {
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },

      doneButton() {
	      this.$store.state.clickedFrom = 'gigCategoryFilterModal'
        this.EventBus.$emit('gigCategoryFilter', {duration: this.duration, selectedCategoryInfo: this.selectedCategoryInfo, searchTerm: this.searchTerm, orderBy: this.orderBy});
        this.hide();
      },

    },

    computed: {
      getModalInfo() {
        let info = this.$store.state.activeModal.info;
        if (info) {
          this.categoryInfo = info.categoryInfo;
          this.duration = info.duration;
          this.categoryInfo.forEach((category) => {
            if (category.selected) {
              this.selectedCategoryInfo = category.slug;
            }
          })
        }
        return true;
      }
    },

    mounted() {
    }
  }

</script>

<style scoped lang="scss">
  .modal-container {
    font-family: 'sofia-pro';
    width: 290px !important;
    margin-top: 55px;
    height: calc(100vh - 90px);
    background-color: #ffffff;
    overflow-y: scroll;
    padding: 0;
  }

  .modal-inner {
    display: flex;
    flex-direction: column;
    padding: 26px 20px 30px;
  }

  .filter-title {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #2d3640;
  }

  .filter-label {
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    color: #a1a9a9;
    margin-top: 24px;
  }

  .super-drop-tek-cizgi {
    margin-top: 10px;
    font-size: 16px !important;
    padding-left: 5px;
    width: 249px;
    background-position-x: 229px;
    position: relative;
  }

  .done-button {
    width: 250px;
    height: 40px;
    border-radius: 2px;
    border: solid 1px #bfc8d2;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
    background-color: #ffffff;
    margin-top: 30px;
  }

  .filter-text {
    margin-top: 10px;
    width: auto;
  }
</style>
